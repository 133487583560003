import React from 'react'
import Logo from 'src/assets/images/inline/logo.svg'
import Img from 'gatsby-image'
import { StaticQuery, graphql } from 'gatsby'
import { Link as InternalLink } from 'gatsby'
import { Link } from 'react-scroll'

import { Section } from '../../../shared'

import styles from './Footer.module.scss'

const Footer = props => {
  return (
    <StaticQuery
      query={graphql`
        query {
          howItWorks: file(relativePath: { eq: "meerkat-footer.png" }) {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid_withWebp_noBase64
              }
            }
          }
        }
      `}
      render={data => (
        <React.Fragment>
          <Section className={styles.container} size={'md'}>
            <div className={styles.innerContainer}>
              <Logo className={styles.logo} />
              {props.isHome && (
                <div className={styles.linkSection}>
                  <span className={styles.sectionTitle}>Product</span>
                  <Link
                    href="#how-it-works"
                    className={styles.link}
                    to="how-it-works"
                    spy={false}
                    smooth={true}
                    offset={props.isMobile ? -100 : -160}
                    duration={500}
                  >
                    How it works
                  </Link>
                  <Link
                    href="#technology"
                    className={styles.link}
                    to="technology"
                    spy={true}
                    smooth={true}
                    offset={props.isMobile ? -100 : -160}
                    duration={500}
                  >
                    Technology
                  </Link>
                  <Link
                    href="#enterprise"
                    className={styles.link}
                    to="enterprise"
                    spy={true}
                    smooth={true}
                    offset={props.isMobile ? -100 : -160}
                    duration={500}
                  >
                    Enterprise
                  </Link>
                  <InternalLink to="/docs" className={styles.link}>
                    Documentation
                  </InternalLink>
                </div>
              )}
              <div className={styles.linkSection}>
                <span className={styles.sectionTitle}>Support</span>
                <Link
                  href="#faq"
                  className={styles.link}
                  to="faq"
                  spy={true}
                  smooth={true}
                  offset={props.isMobile ? -100 : -160}
                  duration={500}
                >
                  FAQ
                </Link>
                <a className={styles.link} href="mailto:meerkat@meerkat.watch">
                  Help
                </a>
                <InternalLink to="/terms" className={styles.link} href="#">
                  Terms
                </InternalLink>
                <InternalLink to="/privacy" className={styles.link} href="#">
                  Privacy Policy
                </InternalLink>
              </div>
              <div className={styles.linkSection}>
                <span className={styles.sectionTitle}>Follow</span>
                <a
                  className={styles.link}
                  href="https://twitter.com/Meerkatswatch"
                >
                  Twitter
                </a>
                <a
                  className={styles.link}
                  href="https://medium.com/@Meerkat.Watch"
                >
                  Medium
                </a>
              </div>
              <div className={styles.linkSection}>
                <span className={styles.sectionTitle}>Contact</span>
                <a className={styles.link} href="mailto:meerkat@meerkat.watch">
                  meerkat@meerkat.watch
                </a>
              </div>
            </div>
            <Img
              lazy
              fluid={data.howItWorks.childImageSharp.fluid}
              fadeIn={false}
              alt="How it works"
            />
          </Section>
        </React.Fragment>
      )}
    />
  )
}

export default Footer
