import React from 'react'
import { Link } from 'gatsby'
import Helmet from 'react-helmet'
import { Navbar, Section } from '../components/shared'
import Footer from '../components/pages/Home/Footer'

import styles from '../assets/css/pages/static.module.scss'

const Terms = () => {
  return (
    <React.Fragment>
      <Helmet title="Meerkat - Terms of Use" />
      <Navbar />
      <Section>
        <h1> MEERKAT CRYPTOCURRENCY WALLET TRACKER BY SALT TERMS OF USE</h1>
        <h2>Last Updated: December 19, 2018</h2>

        <p className={styles.p}>
          The following terms of use (the <b>“Terms”</b>) constitute a binding
          agreement between you and Salt Lending Holdings, Inc. (
          <b>“SALT,” “we,” or “us”</b>) with respect to your use of SALT’s
          Meerkat Cryptocurrency Wallet Tracker app that links to these Terms
          (collectively, the
          <b>“Site”</b>). You must be at least the legal age of majority in your
          place of residence to access and use the Site.
        </p>
        <p className={styles.p}>
          We may revise and update these Terms from time to time and will post
          the updated Terms to the Site. We encourage you to periodically review
          this page for the latest information on our terms of use. If changes
          to these Terms are material (i.e., changes we believe you will find
          important), we will provide notice to you by e-mail or by posting
          notice of the changes on the Site. UNLESS OTHERWISE INDICATED, ANY
          CHANGES TO THESE TERMS WILL APPLY IMMEDIATELY UPON POSTING TO THE
          SITE.
        </p>
        <ol className={styles.ol}>
          <li>
            <b>License; Restrictions on Use</b>
            <p>
              Subject to these Terms, we grant to you a limited, non-exclusive,
              non-transferable, non-sublicensable, revocable license to access
              and use the Site for your personal, non-commercial use, including
              any graphics, text, instructions, images, audio files and/or other
              sounds, videos, and other materials you may view on, access
              through, or are otherwise related to the Site (collectively, the
              <b>“Content”</b>).
            </p>
            <p>You agree not to:</p>
            <ul>
              <li>
                except as otherwise provided in these Terms, copy, download, or
                store the Content in a retrieval system for any other purpose,
                or redistribute, reuse, or modify the Content for any purpose,
                without our express written permission;
              </li>
              <li>
                collect information from the Site using an automated software
                tool or manually on a mass basis;
              </li>
              <li>
                use automated means to access the Site, or gain unauthorized
                access to the Site or to any account or computer system
                connected to the Site;
              </li>
              <li>
                obtain, or attempt to obtain, access to areas of the Site or our
                systems that are not intended for access by you;
              </li>
              <li>
                “flood” the Site with requests or otherwise overburden, disrupt,
                or harm the Site or our systems;
              </li>
              <li>
                send bulk emails, surveys, or other mass messaging, whether
                commercial in nature or not;
              </li>
              <li>
                use the Site to promote or operate any service or content
                without our prior written consent;
              </li>
              <li>
                restrict or inhibit other users from accessing or using the
                Site;
              </li>
              <li>
                modify or delete any copyright, trademark, or other proprietary
                rights notices that appear on the Site or in the Content;
              </li>
              <li>
                engage in or create any unlawful gambling, sweepstakes, or
                pyramid scheme;
              </li>
              <li>imply any endorsement by SALT;</li>
              <li>
                intentionally or unintentionally violating any local, state,
                federal, national or international law, including, but not
                limited to, rules, guidelines, and/or regulations decreed by the
                U.S. Securities and Exchange Commission, in addition to any
                rules of any nation or other securities exchange, that would
                include without limitation, the New York Stock Exchange, the
                American Stock Exchange, or the NASDAQ, and any regulations
                having the force of law;
              </li>
              <li>
                provide support or resources to any organization designated by
                the United States government as a "foreign terrorist
                organization" pursuant to Section 219 of the Immigration
                Nationality Act; or
              </li>
              <li>
                access or use the Site or any Content for any unlawful purpose
                or otherwise beyond the scope of the rights granted to you in
                these Terms.
              </li>
            </ul>
          </li>
          <li>
            <b>Ownership</b>
            <p>
              The Site and the Content are owned by SALT and its licensors, and
              are protected under copyright, trademark, and other applicable
              United States and international laws and treaties. Without
              limiting the foregoing, the trademarks, trade names, service
              marks, and logos displayed on the Site are registered and
              unregistered marks of SALT and its licensors. You acknowledge and
              agree that, as between you and SALT, SALT is and shall remain the
              sole owner of the Site and the Content, including, without
              limitation, all patents, copyrights, trademarks, trade secrets,
              and other intellectual property and proprietary rights therein and
              thereto.
            </p>
            <p>
              Pursuant to Section 512(c)(2) of the Copyright Act, SALT
              designates the following agent to receive notifications of claimed
              infringement :
            </p>
            <address>
              support@saltlending.com
              <br />
              Legal Department
              <br />
              Salt Lending Holdings, Inc.
              <br />
              07 17th Street, Suite 3200
              <br />
              Denver, CO 80202
              <br />
            </address>
          </li>
          <li>
            <b>Proprietary Rights</b>
            <p>
              The Site and any software or technology that may be used in
              connection with the Site may contain proprietary and confidential
              material that may be protected by intellectual property rights and
              other laws. Except as may be expressly authorized by SALT, or as
              otherwise permitted by applicable law, you agree that you shall
              not alter, modify, lease, rent, loan, sell, transmit, distribute,
              broadcast, publicly perform or create any derivative works which
              are based upon, or derived from, the Site (including any Content,
              or any software or services provided or made available therefrom).
            </p>
          </li>
          <li>
            <b>Children</b>
            <p>
              By using the Site, you represent and warranty that you are at
              least the age of majority in your place of residence and can
              legally enter into a binding agreement with us. We disclaim all
              and any liability for any misrepresentation of your own, or any
              other user’s age. You further represent any warrant that you are
              not prohibited by any applicable law from using the Site.
            </p>
          </li>
          <li>
            <b>Site Availability</b>
            <p>
              We reserve the right to make changes to, suspend, or discontinue
              (temporarily or permanently) the Site or any portion thereof
              (including any Content) at any time. You agree that we will not be
              liable to you or to any third party for any such change,
              suspension, or discontinuance.
            </p>
            <p>
              In particular, we may, without any prior notice, suspend,
              terminate and/or limit your account and access to the Site at any
              time in the event of:
            </p>
            <ul>
              <li>
                any breach or violation of this Agreement or any other
                incorporated agreement, regulation and/or guideline;
              </li>
              <li>
                any requests from law enforcement or any other governmental
                agencies;
              </li>
              <li>
                the discontinuance, alteration and/or material modification to
                the Site, or any part thereof;
              </li>
              <li>unexpected technical or security issues and/or problems;</li>
              <li>any extended periods of inactivity;</li>
              <li>
                any engagement by you in any fraudulent or illegal activities;
                and/or
              </li>
              <li>
                the nonpayment of any associated fees that may be owed by you in
                connection with the Site.
              </li>
            </ul>
            <p>
              Furthermore, you hereby agree that any and all terminations,
              suspensions, discontinuances, and or limitations of access for
              cause shall be made at our sole discretion and that we shall not
              be liable to you or any other third party with regards to the
              termination of your account, associated email address and/or
              access to any of the Site or any Paid Services.
            </p>
            <p>
              The termination of your account shall include any and/or all of
              the following:
            </p>
            <ul>
              <li>
                the removal of any access to all or any part of the Site;{' '}
              </li>
              <li>
                the immediate cessation of any cryptocurrency wallet
                notifications;{' '}
              </li>
              <li>
                the deletion of any and all related information, files, and any
                such content that may be associated with or inside your account,
                or any part thereof; and{' '}
              </li>
              <li>
                the barring of any further use of all or part of the Site.{' '}
              </li>
            </ul>
            <p>
              At the termination of these Terms, any provisions that would be
              expected to survive termination by their nature shall remain in
              full force and effect.
            </p>
          </li>
          <li>
            <b>Privacy Policy</b>
            <p>
              All personally identifiable information that the Site may collect
              is subject to, and treated in accordance with, the terms of SALT's
              Meerkat Privacy Policy <Link to="/privacy">Privacy Policy</Link>.
            </p>
          </li>
          <li>
            <b>Third-Party Materials, Goods and Services</b>
            <p>
              The Site may display, include, or make available third-party
              content (including data, information, applications and other
              products services and/or materials) or provide links to
              third-party websites or services (collectively,{' '}
              <b>“Third Party Materials”</b>). You acknowledge and agree that
              SALT is not responsible for Third Party Materials, including their
              accuracy, completeness, timeliness, validity, legality, decency,
              quality, or any other aspect thereof. SALT does not assume and
              will not have any liability to you or any other person or entity
              for any Third Party Materials. Representations made regarding
              products and services provided by third parties will be governed
              by the policies and representations made by these third parties.
              SALT is not responsible or liable for your transactions, dealings
              or interactions with third parties.
            </p>
          </li>
          <li>
            <b>Indemnification</b>
            <p>
              You agree to indemnify, defend, and hold SALT and its affiliates,
              licensors, partners, and service providers, and the officers,
              directors, employees, agents, and representatives of each of them
              (collectively, the <b>“SALT Entities”</b>) harmless from and
              against any claims, liabilities, losses, damages, judgments,
              awards, costs, and expenses (including reasonable attorneys’ fees)
              arising out of or resulting from your use of the Site and/or any
              Content, or any violation of these Terms or applicable law. We
              reserve the right, at our own expense, to assume the exclusive
              defense and control of any action subject to indemnification by
              you, and in such event you agree to cooperate with us in defending
              such action. You agree not to settle any such matter without the
              prior written consent of SALT.
            </p>
          </li>
          <li>
            <b>Import/Export Compliance</b>
            <p>
              When using the Site, you agree to comply with all local laws,
              rules and regulations applicable to online conduct ad acceptable
              content. You agree that your use of the Site will comply with all
              applicable import and export laws, rules and regulations,
              including but not limited to the United States Export
              Administration Regulations and Sanctions Programs.
            </p>
          </li>
          <li>
            <b>DISCLAIMERS</b>
            <p>
              THE SITE AND THE CONTENT ARE PROVIDED TO YOU FOR INFORMATION
              PURPOSES ONLY ON AN “AS IS” AND “AS AVAILABLE” BASIS, WITHOUT
              WARRANTIES OF ANY KIND, AND SALT HEREBY DISCLAIMS ALL WARRANTIES,
              WHETHER EXPRESS, IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT
              NOT LIMITED TO ANY IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS
              FOR A PARTICULAR PURPOSE, AND NON-INFRINGEMENT. SALT DOES DO NOT
              MAKE ANY WARRANTY OR REPRESENTATION WITH RESPECT TO THE
              COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
              AVAILABILITY OF THE SITE OR ANY CONTENT, NOR DOES SALT REPRESENT
              OR WARRANT THAT DEFECTS WILL BE CORRECTED, THAT THE SITE, CONTENT,
              OR THE SERVERS THAT MAKES THEM AVAILABLE ARE FREE OF VIRUSES OR
              OTHER HARMFUL COMPONENTS, OR THAT THE SITE OR THE CONTENT WILL
              OTHERWISE MEET YOUR NEEDS OR EXPECTATIONS. ACCORDINGLY, SALT IS
              NOT LIABLE TO YOU FOR ANY LOSS OR DAMAGE THAT MIGHT ARISE, FOR
              EXAMPLE, FROM THE SITE’S INOPERABILITY, UNAVAILABILITY, DOWNTIME
              OR SECURITY VULNERABILITIES OR FROM YOUR RELIANCE ON THE QUALITY,
              ACCURACY, AVAILABILITY, OR RELIABILITY OF THE INFORMATION FOUND
              ON, USED ON, OR MADE AVAILABLE THROUGH THE SITE.
            </p>
            <p>
              THE SITE AND CONTENT SHALL NOT BE A SUBSTITUTE FOR TRADING,
              INVESTMENT OR ANY OTHER ADVICE FROM A LICENSED PROFESSIONAL. SALT
              SHALL NOT BE RESPONSIBLE OR LIABLE FOR ANY TRADING AND/OR
              INVESTMENT DECISIONS BASED ON ANY INFORMATION OBTAINED VIA THE
              SITE.
            </p>
          </li>
          <li>
            <b>LIMITATION OF LIABILITY</b>
            <p>
              TO THE FULLEST EXTENT PERMITTED BY LAW, IN NO EVENT WILL SALT BE
              LIABLE FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, SPECIAL,
              PUNITIVE, OR EXEMPLARY DAMAGES ARISING FROM OR RELATED TO YOUR USE
              OF OR INABILITY TO USE THE SITE OR THE CONTENT, WHETHER CAUSED BY
              TORT (INCLUDING NEGLIGENCE), BREACH OF CONTRACT, OR OTHERWISE,
              EVEN IF FORESEEABLE. SALT’S MAXIMUM AGGREGATE LIABILITY TO YOU FOR
              LOSSES OR DAMAGES THAT YOU SUFFER IN CONNECTION WITH THESE TERMS
              OF USE OF THE SITE OR ANY CONTENT IS LIMITED TO U.S. $ 10.00. THE
              FOREGOING LIMITATIONS SHALL APPLY EVEN IF YOUR REMEDIES UNDER
              THESE TERMS OF USE FAIL OF THEIR ESSENTIAL PURPOSE.
            </p>
            <p>
              Some jurisdictions do not allow the exclusion or limitation of
              certain warranties or consequential damages, so some of the
              exclusions and/or limitations in Section 10 and this Section 11
              may not apply to you.
            </p>
          </li>
          <li>
            <b>Reservation Of Rights</b>
            <p>
              SALT reserves all of SALT's rights, including but not limited to
              any and all copyrights, trademarks, patents, trade secrets, and
              any other proprietary right that SALT may have in and to the Site
              and the Content. Other than as set forth in these Terms, the use
              of SALT's rights and property requires SALT’s prior written
              consent. By making services available to you, SALT is not
              providing you with any implied or express licenses or rights, and
              you will have no rights to make any commercial use of the Site
              without SALT's prior written consent.
            </p>
          </li>
          <li>
            <b>Disclosures and Disclaimers</b>
            <p>
              Nothing contained on the Site constitutes tax, accounting,
              regulatory, legal, insurance or investment advice. Neither the
              information, nor any opinion, contained on this Site constitutes a
              solicitation or offer by SALT or its affiliates to buy or sell any
              securities, futures, options or other financial instruments, nor
              shall any such security be offered or sold to any person in any
              jurisdiction in which such offer, solicitation, purchase, or sale
              would be unlawful under the securities laws of such jurisdiction.
              Decisions based on information contained on this Site are the sole
              responsibility of the visitor and the information contained on the
              Site has been prepared without reference to any particular user's
              investment requirements or financial situation.
            </p>
            <p>
              SALT makes no representations that the Content is appropriate for
              use in all locations, or that the transactions, securities,
              products, instruments, or services discussed on the Site are
              available or appropriate for sale or use in all jurisdictions or
              countries, or by all investors. You acknowledge and agree that
              SALT has no control over, or liability for, any information
              included in, or arising from blockchain ledgers.
            </p>
          </li>
          <li>
            <b>Miscellaneous</b>
            <p>
              <b>Applicable Law, Jurisdiction and Venue</b>. You agree that
              these Terms and any dispute arising out of your use of the Site,
              Content, or products purchased through the Site shall be governed
              by and construed in accordance with the laws of the State of
              Colorado, without regard to any conflict of law provisions. Any
              legal suit, action, or proceeding arising out of or related to
              these Terms, the Site or Content shall be instituted exclusively
              in the federal or state courts located in Denver, Colorado. You
              agree to waive any and all objections to the exercise of
              jurisdiction over you by such courts and to venue in such courts.
            </p>
          </li>
        </ol>
        <p className={styles.p}>
          <b>Geographic Restrictions.</b> SALT is based in the State of Colorado
          in the United States. SALT makes no claims that the Site or Content
          are accessible or appropriate outside of the United States. Access to
          and use of the Site may not be legal by certain persons or in certain
          countries. If you access the Site from outside the United States, you
          do so on your own initiative and are responsible for compliance with
          local laws.
        </p>
        <p className={styles.p}>
          <b>Waiver of Jury Trial</b> YOU HEREBY IRREVOCABLY WAIVE ALL RIGHT TO
          TRIAL BY JURY IN ANY ACTION, SUIT, PROCEEDING, CLAIM, OR COUNTERCLAIM
          ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE, SERVICES
          OR CONTENT.
        </p>
        <p className={styles.p}>
          <b>Waiver and Severability.</b> Our failure to exercise or enforce any
          right or provision of these Terms will not constitute a waiver of such
          right or provision. If any provision of these Terms is held by a court
          of competent jurisdiction to be invalid, illegal, or unenforceable for
          any reason, such provision shall be eliminated or limited to the
          minimum extent possible, and the remaining provisions of these Terms
          of Use will continue in full force and effect.
        </p>
        <p className={styles.p}>
          <b>Entire Agreement</b> These Terms constitute the sole and entire
          agreement between you and SALT with respect to the subject matter
          hereof, and supersede and replace all prior or contemporaneous
          understandings or agreements, written or oral, regarding such subject
          matter.
        </p>
        <p className={styles.p}>
          <b>Questions.</b> If you have any questions about the Site or these
          Terms, please contact us at <b>support@meerkat.watch</b>.
        </p>
      </Section>
      <Footer />
    </React.Fragment>
  )
}

export default Terms
